import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb capell de 4 a 10 cm de diàmetre, al principi convex després aplanat. Presenta una zonació de color groc taronjós a taronja rosat. Presenta marges enrotllats llarg temps. Làmines de color crema, decurrents, atapeïdes i molt nombroses. El peu és cilíndric, de 3-7 x 1,5-2 cm, un poc atenuat a la base del mateix color que el capell. Les espores són de color blanc crema en massa, d’ovoïdals a subgloboses amb berrugues poc denses, de 7-9 x 6-8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      